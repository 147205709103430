import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Button } from 'react-bootstrap';

import '../generic-page.scss';
import './play-on-pc.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const BCMPlayonPCPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page play-on-pc-page bcm'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>Play on PC</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/blackclover/categories/cat_pc.jpg"
            alt="Play on PC"
          />
        </div>
        <div className="page-details">
          <h1>Play Black Clover Mobile on PC</h1>
          <h2>How to play BCM (KR, JPN and Global) gacha game on your PC.</h2>
        </div>
      </div>
      <div className="page-content">
        <div className="banner play-on-pc bcm">
          <div className="cta">
            <h4>Play Black Clover M on PC</h4>
            <OutboundLink
              href="https://www.ldplayer.net/games/black-clover-m-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
              target="_blank"
            >
              <Button variant="primary">Play now on LDPlayer</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="Play on PC" />
        <p>
          Black Clover M is an anime-style RPG adventure game published by
          Garena International II. LDPlayer is the best platform to enjoy this
          game on PC, offering an immersive and enhanced gaming experience.
        </p>
        <SectionHeader title="What is LDPlayer?" />
        <p>
          LDPlayer is an Android emulator for Windows that is built on 64-bit
          architecture and supports apps and games from the Play Store. You can
          now enjoy your favorite games on your PC instead of just playing them
          on your phone!
        </p>
        <SectionHeader title="Benefits of using LDPlayer" />
        <p>
          Playing Black Clover M: Rise Of The Wizard King on LDPlayer offers
          lots of advantages:
        </p>
        <ul>
          <li>
            Multiple instances. It’s perfect for managing multiple accounts and
            rerolling more efficiently.
          </li>
          <li>
            Macro. you can use macros to enhance your gaming experience, let’s
            forget the repetitive tasks and save valuable time!
          </li>
          <li>
            Big screen. Play the game with larger screen and enjoy the every
            detail of the fantastic graphics. No need to worry about battery and
            heating issues of your mobile phones anymore!
          </li>
        </ul>
        <SectionHeader title="Recommended PC configuration" />
        <p>
          The following specifications are recommended for playing one or two
          instances:
        </p>
        <ul>
          <li>CPU: Intel or AMD Processor like x86 or x86_64</li>
          <li>
            System: Win7 / Win8 / Win8.1 / Win10 / Win11 (Do not support Mac
            version)
          </li>
          <li>
            Graphics Driver: Windows DirectX 11 or Graphics driver with OpenGL
            2.0
          </li>
          <li>RAM: 16GB</li>
          <li>Disk Space: Minimum 100GB</li>
          <li>
            VT: Virtualization Technology (Intel VT-x/AMD-V) enabled.{' '}
            <a
              href="https://www.ldplayer.net/blog/how-to-enable-vt.html"
              target="_blank"
              rel="noreferrer"
            >
              Simple Steps to enable VT
            </a>
            .
          </li>
        </ul>
        <p>
          If you have needs for playing multiple instances, it's recommended to
          use the CPU with higher numbers of cores and graphics card with higher
          vram. You can refer to this tutorial{' '}
          <a
            href="https://www.ldplayer.net/blog/how-to-open-as-many-instances-of-LDPlayer-as-possible.html"
            target="_blank"
            rel="noreferrer"
          >
            How to open as many instances of LDPlayer as possible
          </a>
          .
        </p>
        <SectionHeader title="Play Black Clover M and support Prydwen" />
        <p>
          If you download LDPlayer and play Black Clover M (or other games) on
          it, using our promotional link, you will support the website. Thank
          you!
        </p>
        <OutboundLink
          href="https://www.ldplayer.net/games/black-clover-m-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
          target="_blank"
        >
          <Button variant="primary">Play now on LDPlayer</Button>
        </OutboundLink>
      </div>
    </DashboardLayout>
  );
};

export default BCMPlayonPCPage;

export const Head: React.FC = () => (
  <Seo
    title="Play on PC | Black Clover M | Prydwen Institute"
    description="Play Black Clover Mobile (KR, JPN and Global) gacha game on your PC."
    game="bcm"
  />
);
